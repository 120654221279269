import {ColumnMain} from "#/models/column-main";
import {Column} from "#/models/column";
import {Trans} from "@lingui/macro";
import {Tag} from "antd";
import React from "react";

const CameraReadingModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'id', width: 80, filter:true, sorter:true,title:<Trans>#</Trans>});
    columns.push({key:'data', width: 250, filter:true, sorter:true,title:<Trans>Data</Trans>});
    columns.push({
        key: 'result',
        uniqueSelectFilterData: [
            {
                label: <Trans>All</Trans>,
                value: null,
            },
            {
                label: <Trans>OK_</Trans>,
                value: 'OK',
            },
            {
                label: <Trans>BOSCH_CODE_IS_NOK</Trans>,
                value: 'BOSCH_CODE_IS_NOK',
            },
            {
                label: <Trans>KEYENCE_DECISION_IS_NOK</Trans>,
                value: 'KEYENCE_DECISION_IS_NOK',
            }
        ],
        sorter: true,
        width: 150,
        title: <Trans>Result</Trans>,
        render: text => {
            let color = 'gray';
            let transText;
            if (text === 'BOSCH_CODE_IS_NOK') {
                color = 'red';
                transText = <Trans>BOSCH_CODE_IS_NOK</Trans>;
            } else if (text === 'KEYENCE_DECISION_IS_NOK') {
                color = 'red';
                transText = <Trans>KEYENCE_DECISION_IS_NOK</Trans>;
            } else if (text === 'OK') {
                color = 'green';
                transText = <Trans>OK_</Trans>;
            }
            return (
                <Tag color={color} key={text}>
                    {transText}
                </Tag>
            );
        },
    });



    columns.push({key:'updatedDate', width: 150, dateTimeFilter:true, sorter:true,title:<Trans>Updated Date</Trans>});
    columns.push({key:'updatedBy', width: 150, sorter:true,title:<Trans>Updated By</Trans>});
    columns.push({key:'createdDate', width: 150, dateTimeFilter:true, sorter:true,title:<Trans>Created Date</Trans>,direction:"desc"});
    columns.push({key:'createdBy', width: 150, sorter:true,title:<Trans>Created By</Trans>});


    return{
        columns:columns,
        url:"/resource/camerareadings"
    };

}
export default CameraReadingModel();
