import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";

const UsersListModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'loginName', filter:true, sorter:true,title:<Trans>Login Name</Trans>, direction:"asc"});
    columns.push({key:'firstName', filter:true, sorter:true,title:<Trans>First Name</Trans>});
    columns.push({key:'lastName', filter:true, sorter:true,title:<Trans>Last Name</Trans>});
    columns.push({key:'updatedDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated Date</Trans>});
    columns.push({key:'updatedBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated By</Trans>});
    columns.push({key:'createdDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Created Date</Trans>,direction:"desc"});
    columns.push({key:'createdBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Created By</Trans>});


    return{
        columns:columns,
        url:"/resource/user/get-all-users"
    };

}
export default UsersListModel();
